.post-card {
	padding: 70px 0 70px;
	border-bottom: 1px solid $white;

	img {
		margin-top: 0;
	}

	.regular-link {
		margin-top: 30px;
		display: block;
	}
}

.media-card {
	margin-bottom: 50px;
}

.article-excerpt {
	margin-bottom: 70px;
}

.article-big-image {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto 70px;

	@include below($s) {
		margin-bottom: 40px;
	}
}

.regular-p p {
	margin: 25px 0;
}

.regular-p h2,
.regular-p h3,
.regular-p h4 {
	font-weight: 600;
	margin: 20px 0;
}

.regular-p a {
	color: $blue;

	&:hover {
		text-decoration: none;
	}
}

.regular-p .wp-block-quote p {
	font-size: 30px;
	line-height: 40px;
	font-style: italic;
	margin: 50px auto;

	@include below($s) {
		font-size: 20px;
		line-height: 30px;
	}
}