.section-top {
	width: 100%;
	height: 70vh;
	min-height: 693px;
	position: relative;
	background: url('../img/blavit-arme.png') no-repeat center center;
	background-size: cover;
	height: 80vh;

	@include below($s) {
		min-height: 0;
	}

	.top-layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(29, 51, 217, .8);

		.big-logo {
			@include align-both(absolute);

			@include below($s) {
				max-width: 80%;
			}
		}

		.icon-scroll-arrow-down {
			position: absolute;
			width: 16px;
			bottom: 27px;
			left: 50%;
			margin-left: -8px;
		}
	}

	.bottom-layer {

	}
}