@font-face {
  font-family: 'GTAmerica-Bold';
  src: url('../fonts/GTAmerica-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/GTAmerica-Bold.otf')  format('opentype'),
	     url('../fonts/GTAmerica-Bold.woff') format('woff'), url('../fonts/GTAmerica-Bold.ttf')  format('truetype'), url('../fonts/GTAmerica-Bold.svg#GTAmerica-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTAmericaMono-Regular';
  src: url('../fonts/GTAmericaMono-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/GTAmericaMono-Regular.otf')  format('opentype'),
	     url('../fonts/GTAmericaMono-Regular.woff') format('woff'), url('../fonts/GTAmericaMono-Regular.ttf')  format('truetype'), url('../fonts/GTAmericaMono-Regular.svg#GTAmericaMono-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

$titleFont: 'GTAmerica-Bold';
$bodyFont: 'GTAmericaMono-Regular';