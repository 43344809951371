@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';

// Stuff
@import 'fonts';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$contentWidth: 1030px;
$contentPadding: 15px;
$contentMaxWidth: 90%;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$blue: #2435C7;
$black: #000000;
$white: #FFFFFF;

.c-blue {
	color: $blue;
}

.c-black {
	color: $black;
}

.c-white {
	color: $white;
}

.bg-blue {
	background-color: $blue;
}

.bg-white {
	background-color: $white;
}

// Type
.title-s {
	font-size: 24px;
	line-height: 30px;
	font-family: $titleFont;
	font-style: italic;
}

.title-m {
	font-size: 40px;
	line-height: 45px;
	font-family: $titleFont;
	font-style: italic;
	margin: 10px 0 20px;

	@include below($s) {
		font-size: 25px;
		line-height: 30px;
	}
}

.title-l {
	font-size: 70px;
	font-family: $titleFont;
	font-style: italic;
	width: 100%;
	max-width: 800px;
	margin: 30px auto;

	@include below($s) {
		font-size: 40px;
	}
}

.link-title,
.link-title a {
	font-size: 24px;
	line-height: 34px;

	&:hover {
		text-decoration: none;
	}

	@include below($s) {
		font-size: 18px;
		line-height: 28px;
	}
}

.link-title a {
	text-decoration: underline;
	display: block;
}

.link-title a:hover {
	text-decoration: none;
}

.info-list {
	font-size: 18px;
	line-height: 28px;
	margin-top: 45px;
}

.info-list p {
	text-align: center !important;
}

.floating-info-list {
	position: relative;
	overflow: hidden;
	margin-top: 70px;

	@include below($s) {
		margin-top: 30px;
	}

	p {
		text-align: left;
	}

	.col-2 {
		width: 30%;
		float: left;
		text-align: left;

		p {
			text-align: left !important;
		}

		&:last-child {
			float: right;
		}

		@include below($s) {
			width: 100%;
			text-align: center;
			margin: 20px 0;

			p {
				text-align: center !important;
			}
		}
	}
}

.date {
	font-size: 14px;
	font-family: $titleFont;
	font-style: italic;
}

.date-mono {
	font-family: $bodyFont;
	font-style: normal;
	margin-bottom: 10px;
	display: block;
}

.opa-70 {
	opacity: .7;
}

.intro-p,
.intro-p p {
	font-size: 24px;
	line-height: 34px;
	width: 100%;
	max-width: 590px;
	margin: 0 auto;

	@include below($s) {
		font-size: 18px;
		line-height: 28px;
	}
}

.regular-p,
.has-medium-font-size,
.article-holder p {
	font-size: 18px;
	line-height: 34px;
	text-align: left;

	@include below($s) {
		font-size: 14px;
		line-height: 30px;
	}
}

.article-holder {
	width: 100%;
	max-width: 590px;
	margin: 0 auto;

	img {
		width: 100%;
		display: block;
		margin: 45px 0;
	}
}

// Globals
body {
	font-family: $bodyFont;
	padding-top: 88px;

	@include below($s) {
		padding-top: 64px;
	}
}

.align-c {
	text-align: center;
}

.align-l {
	text-align: left;
}

// Holders and content
.regular-section {
	display: block;
	width: 100%;
	padding: 100px 20px;

	@include below($s) {
		padding: 50px 20px;
	}
}

// Buttons
.btn-holder {
	text-align: center;
	margin-top: 60px;
}

.btn {
	display: inline-block;
	background: $blue;
	color: white;
	font-weight: 600;
	text-align: center;
	border-radius: 25px;
	padding: 15px 50px 17px;
	text-decoration: none;
}

.regular-link {
	color: $white;
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;

	&:hover {
		text-decoration: none;
	}
}

.load-more {
	margin-top: 70px;
	display: block;
}

// Compontents
@import 'header';
@import 'top-section';
@import 'slider';
@import 'article';

// Footer 
.footer-content {
	padding: 110px 20px;

	@include below($m) {
		padding: 55px 20px;
	}
}

.footer-inside {
	width: 100%;
	max-width: 1248px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}

.footer-nav {
	position: relative;
	overflow: hidden;
	width: 70%;
	float: left;

	@include below($m) {
		width: 100%;
	}

	.col-3 {
		width: 33.33%;
		float: left;

		@include below($s) {
			width: 100%;
			margin-bottom: 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid $white;
		}
	}

	p {
		font-size: 16px;
		line-height: 30px;
		color: $white;
	}

	a {
		color: $white;
		display: block;
		font-size: 16px;
		line-height: 30px;

		&:hover {
			text-decoration: none;
		}

		&.footer-active {
			opacity: .5;
		}
	}
}

.footer-home {
	width: 100%;
	max-width: 206px;
	float: right;

	img {
		width: 100%;
		display: block;
	}

	@include below($m) {
		max-width: 100%;
		margin-top: 30px;

		img {
			max-width: 300px;
			margin: 0 auto;
		}
	}
}