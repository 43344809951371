.slider-section {
	margin: 0 auto;
	display: block;
}

.slider-holder {
	margin: 0 auto;
	display: block;
	position: relative;
	width: 100%;
	max-width: 1440px;
}

.animate-opacity {
	animation: opac 0.8s
}

@keyframes opac {
	from{opacity:0} 
	to{opacity:1}
}

.slider-nav {
	position: absolute;
	width: 100%;
	text-align: center;
	left: 0;
	bottom: 28px;

	button {
		width: 13px;
		height: 13px;
		border-radius: 50px;
		background: $white;
		border: 1px solid $blue;
		-webkit-appearance: none;
		padding: 0;
		outline: 0;

		&.w3-red {
			background: $blue;
		}
	}
}

.mySlides {
	width: 100%;
}

.slider-btn {
	position: absolute;

	&.w3-display-left {
		width: 16px;
		height: 16px;
		-webkit-appearance: none;
		left: 25px;
		top: 50%;
		margin-top: -8px;
		padding: 0;
		background: transparent;
		border: 0;
		outline: 0;
	}

	&.w3-display-right {
		width: 16px;
		height: 16px;
		-webkit-appearance: none;
		right: 25px;
		top: 50%;
		margin-top: -8px;
		padding: 0;
		background: transparent;
		border: 0;
		outline: 0;
	}
}