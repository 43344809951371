.navigation-holder {
	text-align: center;
	padding: 30px 20px;
	position: relative;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	background: $white;
	box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.06);

	@include below($m) {
		padding: 10px 20px;
	}
}

.logo-home {
	height: 66px;
	position: absolute;
	left: 20px;
	top: 50%;
	margin-top: -33px;

	@include below($m) {
		position: relative;
		top: auto;
		left: auto;
		float: left;
		margin-top: 0;
	}

	img {
		height: 100%;
		width: auto;
	}

	@include below($s) {
		height: 44px;
	}
}

.menu-btn {
	color: $blue;
	text-decoration: none;
	font-size: 16px;
	padding: 0 0 0 25px;
	position: absolute;
	right: 20px;
	top: 20px;
	padding-top: 13px;
	padding-bottom: 13px;

	@include above($m) {
		display: none;
	}

	@include below($s) {
		top: 8px;
	}
}

.nav {
	list-style: none;
	display: inline-block;

	li {
		float: left;

		a {
			color: $black;
			text-decoration: none;
			font-size: 16px;
			padding: 0 25px;

			&:hover,
			&.active {
				color: $blue;
			}
		}
	}

	@include below($m) {
		width: 100%;
		display: none;

		li {
			float: none;
			display: block;
			text-align: right;

			a {
				padding: 10px 0;
				display: inline-block;
			}
		}

		&.visible {
			display: inline-block;
		}
	}
}